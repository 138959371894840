import {
  IonButton, IonCard, IonCardContent, IonCardTitle, IonCol, IonContent,
  IonGrid, IonHeader, IonIcon, IonPage, IonRow
} from '@ionic/react';
import PageNavBar from '../components/PageNavBar';
import { aperture, hammer, qrCode, search } from 'ionicons/icons';

const Home: React.FC = () => {
  return (
    <IonPage>
      <IonHeader collapse="fade">
        <PageNavBar />
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>
          <IonCardTitle>What would you like to do?</IonCardTitle>
          <IonCardContent>
            <IonGrid>
              <IonRow>
                <IonCol>
                  <IonButton size="large" routerLink="/scan">
                    <IonIcon slot="start" icon={aperture}></IonIcon>
                    scan part
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton size="large" disabled={true}>
                    <IonIcon slot="start" icon={search}></IonIcon>
                    search for part
                  </IonButton>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol>
                  <IonButton size="large" disabled={true}>
                    <IonIcon slot="start" icon={hammer}></IonIcon>
                    help with project
                  </IonButton>
                </IonCol>
                <IonCol>
                  <IonButton size="large" disabled={true}>
                    <IonIcon slot="start" icon={qrCode}></IonIcon>
                    find part on shelf
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonCardContent>
        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Home;
