import { useEffect, useState } from "react";

export const enum Status {
  UNINITIALIZED,
  LOADING,
  LOADED
}

// TODO: figure out global context stuff

// TODO: reduce file size; if we do not use the SINGLE_FILE option, then
//   the .wasm file is 30% smaller as is the gz file.
//   https://stackoverflow.com/questions/46332699/how-can-i-load-wasm-files-stored-in-a-subdirectory
//   https://gist.github.com/surma/b2705b6cca29357ebea1c9e6e15684cc

// TODO: could also serve as gzip (transparently)
// TODO: ideally, pre-compress as gzip and have browser decompress automatically
//   or, less ideally, do the decompression in javascript
// https://stackoverflow.com/questions/17581830/load-node-js-module-from-string-in-memory
// pako gzip, or does node have a gzip?

// TODO: create minimal build

// TODO https://gist.github.com/surma/b2705b6cca29357ebea1c9e6e15684cc

// one method: create context.tsx file; createContext, then useContext in custom hook
// https://stackoverflow.com/questions/66828818/sharing-data-between-two-tabs-in-ionic-react

const useWasmModule = (path: string) => {

  const [status, setStatus] = useState(Status.UNINITIALIZED);
  const [module, setModule] = useState<any>();

  useEffect(() => {
    const instantiate = async () => {
      // TODO: error handling; try/catch or .then/.catch?
      setStatus(Status.LOADING);
      console.log('about to instantiate');
      // TODO: path variable should be used below, but then webpack doesn't pack the file
      const newModule = await require('../assets/opencv/opencv.js');
      setStatus(Status.LOADED);
      setModule(newModule);
      console.log(`instantiated module ${path}`);
    }
    instantiate();
  }, [path]);

  return {
    status,
    module
  }

}

export default useWasmModule;
