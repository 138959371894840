import { useEffect, useState } from "react";

export const enum Status {
  UNINITIALIZED,
  LOADING,
  LOADED
}

const useFetch = <T=any>(url: string, method='POST', params={}) => {

  const [status, setStatus] = useState(Status.UNINITIALIZED);
  const [data, setData] = useState<T>();
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setStatus(Status.LOADING);
      fetch(url, {
        method: method,
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(params),
      })
      .then(response => response.json())
      .then(data => {
        setData(data);
        setStatus(Status.LOADED);
        console.log(`fetched data from ${url}`)
      })
      .catch((error) => {
        setError(error);
        setStatus(Status.UNINITIALIZED);
      });
    }
    if (status === Status.UNINITIALIZED) {
      fetchData();
    }
  }, []);

  return {
    data, status, error
  }

}

export default useFetch;
