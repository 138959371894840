import { IonButton, IonButtons, IonContent, IonHeader, IonPage } from '@ionic/react';
import { useCallback, useEffect, useRef, useState } from 'react';
import CameraPreview from '../components/CameraPreview';
import PageNavBar from '../components/PageNavBar';

const Scan: React.FC = () => {

  const [curFrame, setCurFrame] = useState<ImageData>();

  const tester = useCallback(async (timestamp: number, frame: ImageData) => {
    setCurFrame(frame);
  }, []);

  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (!canvasRef.current || !curFrame) return;
    const context = canvasRef.current.getContext('2d');
    if (!context) return;
    context.putImageData(curFrame, 0, 0);

  }, [canvasRef, curFrame]);

  return (
    <IonPage>
      <IonHeader collapse="fade">
        <PageNavBar />
      </IonHeader>
      <IonContent fullscreen>
        Here's where we gonna scan
        <CameraPreview desiredWidth={1280} frameCallback={tester}></CameraPreview>

        <IonButtons>
          <IonButton slot="start">scan</IonButton>
          <IonButton slot="end">register</IonButton>
        </IonButtons>
        <canvas ref={canvasRef} width="1280" height="720" style={{borderStyle: 'solid'}}></canvas>
      </IonContent>
    </IonPage>
  );
};

export default Scan;
