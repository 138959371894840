import { useEffect, useState } from "react";
import { Storage } from '@ionic/storage';

const useStorage = (name: string, keyName: string, defaultData: any, overwrite=false) => {
  const [store, setStore] = useState<Storage>();
  const [data, setData] = useState<typeof defaultData>();

  useEffect(() => {
    const initStorage = async () => {
      const newStore = new Storage({
        name: name,
      });
      const store = await newStore.create();
      setStore(store);
      let data = await store.get(keyName);
      if (!data || overwrite) {
        data = defaultData;
        store.set(keyName, data);
      }
      setData(data);
    }
    initStorage();
  }, [name, keyName, defaultData, overwrite]);

  const updateData = async (newData: typeof defaultData) => {
    setData(newData);
    store?.set(keyName, newData);
  }

  return {
    data,
    updateData
  }

}

export default useStorage;
