import {
  IonButton,
    IonContent,
    IonHeader, IonPage  } from '@ionic/react';
  import PageNavBar from '../components/PageNavBar';
import { useEffect, useState } from 'react';
import { Browser } from '@capacitor/browser';

  const Results: React.FC = () => {

    const openBrowser = async (url: string) => {
      await Browser.open({url: url});
    }
    //openBrowser('http://www.google.com');

    return (
      <IonPage>
        <IonHeader collapse="fade">
          <PageNavBar />
        </IonHeader>
        <IonContent fullscreen>
          <IonButton onClick={() => openBrowser('http://www.google.com')}>click me</IonButton>
        </IonContent>
      </IonPage>
    );
  };

  export default Results;
