import { IonCard, IonCardContent } from '@ionic/react';
import React from 'react';
import { FrameCallback, useCamera } from '../hooks/useCamera';

interface Props {
  desiredWidth: number;
  frameCallback?: FrameCallback;
}

// TODO: make the app 100% of screen height
// https://stackoverflow.com/questions/38428322/react-component-full-screen-with-height-100

// TODO: https://medium.com/@pdx.lucasm/canvas-with-react-js-32e133c05258
// TODO: https://docs.opencv.org/4.6.0/dd/d00/tutorial_js_video_display.html
// TODO: also create a canvas on top (or 3d context) with same size as video
  // https://stackoverflow.com/questions/32699721/javascript-extract-video-frames-reliably


// TODO: optionally create a debug canvas and context for drawing intermediate images
//   can do this as a passed-in prop


const CameraPreview: React.FC<Props> = (props) => {

  const {videoRef} = useCamera(props.desiredWidth, props.frameCallback);

  return (
    <IonCard>
      <IonCardContent>
        <video ref={videoRef} width="50%"></video>
      </IonCardContent>
    </IonCard>
  );
}

export default CameraPreview;
