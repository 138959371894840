import { useEffect, useRef, useState } from "react";

export type FrameCallback =
  (timestamp: DOMHighResTimeStamp, frame: ImageData) => Promise<void>;

export function useCamera(desiredWidth: number, callback?: FrameCallback) {
  const [stream, setStream] = useState<MediaStream>();
  const [videoSettings, setVideoSettings] = useState<MediaTrackSettings>();
  const [canvas, setCanvas] = useState<HTMLCanvasElement>();

  const videoRef = useRef<HTMLVideoElement>(null);

  // effect for setting up camera stream
  useEffect(() => {
    let mediaStream: MediaStream;
    const initStream = async () => {
      const constraints = {
        audio: false,
        video: {
          width: {
            ideal: desiredWidth || 640
          },
          facingMode: "environment",
          focusMode: "continuous",
          exposureMode: "continuous",
        }
      };

      mediaStream = await navigator.mediaDevices.getUserMedia(constraints);
      // TODO: error handling here
      console.log('created camera stream' + mediaStream);
      setStream(mediaStream);

      const videoSettings = mediaStream.getVideoTracks()[0].getSettings();
      console.log('SETTINGS', videoSettings);
      setVideoSettings(videoSettings);
    }
    initStream();
    return () => {
      mediaStream?.getTracks().forEach(track => track.stop());
    }

  }, [desiredWidth]);

  // effect for setting up canvas element
  useEffect(() => {
    let renderCanvas: HTMLCanvasElement;
    const initCanvas = async () => {
      renderCanvas = document.createElement('canvas');
      renderCanvas.width = videoSettings?.width || 0;
      renderCanvas.height = videoSettings?.height || 0;
      setCanvas(renderCanvas);
    }
    initCanvas();
    return () => {
      renderCanvas?.remove();
    }
  }, [videoSettings]);

  // effect for setting up video ref and callbacks
  useEffect(() => {
    const initVideo = async () => {
      if (!videoRef || !videoRef.current || !stream || !canvas || !canvas.width) return;
      const video = videoRef.current;

      // create image and invoke supplied callback method
      const loop = async (timestamp: DOMHighResTimeStamp, info: VideoFrameMetadata) => {
        if (callback) {
          const context = canvas.getContext('2d', {willReadFrequently: true});
          if (context) {
            context.drawImage(video, 0, 0, canvas.width, canvas.height);
            const image = context.getImageData(0, 0, canvas.width, canvas.height);
            if (image) {
              console.log('IMAGE', image.width, image.height)
              callback(timestamp, image);
            }
          }
        }
        video.requestVideoFrameCallback(loop);
      }

      video.srcObject = stream;
      video.autoplay = true;
      video.playsInline = true;
      video.play()
      .then(() => console.log('PLAYING!'))
      .catch((error) => console.warn(error));
      if (callback) {
        video.requestVideoFrameCallback(loop);
      }
    }
    initVideo();
  }, [canvas, stream, videoRef, callback]);

  const grabFrame = async () => {
    if (!videoRef.current || !canvas) return;
    const context = canvas.getContext('2d');
    context?.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
    const image = context?.getImageData(0, 0, canvas.width, canvas.height);
    return image;
  }

  return {
    stream,
    videoSettings,
    videoRef,
    grabFrame
  };

}
