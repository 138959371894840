import { IonToolbar, IonTitle, IonButtons, IonButton, IonImg, IonBackButton } from '@ionic/react';

// TODO: https://ionicreacthub.com/blog/routing-programmatically-in-ionic-react
// settings -> push this page onto history when moving forward
// back -> pop page from history
// start over -> go back to start page
// on start page, disable back button and clear history
// on scan page, push start onto history; where to do this, in button or on scan page itself?

interface Props {
  settingsEnabled?: boolean;
}

const PageNavBar: React.FC<Props> = ({settingsEnabled=true}) => {
  return (
    <IonToolbar>
      <IonTitle>Skubot SmartPanel App</IonTitle>
      <IonImg></IonImg>
      <IonButtons>
        <IonBackButton defaultHref="/" text="back"></IonBackButton>
      <IonButton routerLink="/">
        start over
      </IonButton>
      <IonButton routerLink="/settings">
        settings
      </IonButton>
      </IonButtons>
    </IonToolbar>
  );
};

export default PageNavBar;
