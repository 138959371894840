import { IonCard, IonContent, IonHeader, IonItem, IonLabel, IonPage, IonSelect, IonSelectOption } from '@ionic/react';
import PageNavBar from '../components/PageNavBar'
import useFetch, { Status as FetchStatus} from '../hooks/useFetch';
import useStorage from '../hooks/useStorage';

interface SettingsData {
  catalog?: string;
  category?: string;
  panelVersion?: string;
}

interface PanelVersion {
  name: string;
  display: string;
  tag: string;
}

interface PanelLayout {
  tag: string;
  data: any;
}

const catalogList = [
  "Lowes",
  "Grainger",
  "Hillman",
  "Other",
]

const categoryList = [
  "Fasteners",
  "Plumbing",
  "Other"
]

const defaultSettings: SettingsData = {
  catalog: catalogList[0],
  category: categoryList[0],
  panelVersion: "",  // TODO
}

// TODO: keep this elsewhere, in resource or asset file
const VERSIONS_URL = "https://smartpanel.skubot.net/mobile/smartpanel/versions/";
const STORAGE_NAME = "smartpanel_data";

const Settings: React.FC = () => {

  console.log('one');
  const {data: settings, updateData: updateSettings} = useStorage(STORAGE_NAME, "settings", defaultSettings, true);

  console.log('two');
  const {data: panelLayouts, updateData: updatePanelLayouts} = useStorage(STORAGE_NAME, "panels", {});

  // TODO: infinite loop somewhere; this doesn't work anymore; check application storage in browser debug
  // TODO: figure out how to properly indent, and apply indentation to file / selected region

  // TODO: should probably cache this at the top level so we don't fetch every time?
  // also would hide load times; check whether we already have data, if so don't load
  // TODO: put this into local storage as well
  // TODO: pass down as props
  const {data: panelVersionList, status: fetchStatus, error: fetchError} =
      useFetch<PanelVersion[]>(VERSIONS_URL);

  const changeValue = async (key: string, value: string) => {
    if (settings && settings[key] !== value) {
      console.log(`changed value "${key}" to "${value}"`);
      settings[key] = value;
      await updateSettings(settings);
    }
  }

  const fetchPanelLayout = async (name: string, tag: string) => {
    if (name in panelLayouts) {
      const layout = panelLayouts[name];
      console.log(`we have ${name} with ${tag}`);
      if (layout.tag == tag) return layout;
      console.log('...but it is not here!');
    }

    const url = VERSIONS_URL + name;
    let result = await fetch(url, {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
      },
    })
    .then(response => response.json())
    .then(data => data)
    .catch((error) => {
      // TODO
    });
    if (result) {
      const layout: PanelLayout = { tag, data: result };
      panelLayouts[name] = layout;
      updatePanelLayouts(panelLayouts);
    }
  }

  const displayPanelVersions = () => {
    console.log('diplay panel versions');
    if (fetchStatus === FetchStatus.UNINITIALIZED || !panelVersionList) return;
    return (
      <IonSelect interface="popover" placeholder="select panel version" value={settings?.panelVersion}
      onIonChange={(e) => {changeValue('panelVersion', e.detail.value)}}>
        {panelVersionList.map((item, key) => (
        <IonSelectOption value={item.name} key={key}>{item.display}</IonSelectOption>
        ))}
      </IonSelect>
    );
  }

  return (
    <IonPage>
      <IonHeader collapse="fade">
        <PageNavBar />
      </IonHeader>
      <IonContent fullscreen>
        <IonCard>

          <IonItem>
            <IonLabel position="stacked">Customer Catalog</IonLabel>
            <IonSelect interface="popover" placeholder="select catalog" value={settings?.catalog}
              onIonChange={(e) => changeValue('catalog', e.detail.value)}>
              { catalogList.map((item, key) => (
                <IonSelectOption value={item} key={key}>{item}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          <IonItem>
            <IonLabel position="stacked">Item Category</IonLabel>
            <IonSelect interface="popover" placeholder="select category" value={settings?.category}
              onIonChange={(e) => changeValue('category', e.detail.value)}>
              { categoryList.map((item, key) => (
                <IonSelectOption value={item} key={key}>{item}</IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>

          <IonItem>
          <IonLabel position="stacked">Panel Version</IonLabel>
            { displayPanelVersions() }
          </IonItem>

        </IonCard>
      </IonContent>
    </IonPage>
  );
};

export default Settings;
