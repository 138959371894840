import { createContext, ReactNode, useEffect, useState } from "react";
import useWasmModule, { Status } from "../hooks/useWasmModule";

// TODO: rename this, maybe just ResourcesProvider or ResourceProvider or OpencvProvider
// TODO: also have a settings provider with all types etc exported
// TODO: move these to a contexts directory

interface StaticResources {
  allReady: boolean;
  cv: any;
}

const initialResources: StaticResources = {
  allReady: false,
  cv: null
}

const context = createContext<StaticResources>(initialResources);

interface Props {
  children: ReactNode,
}


const StaticResourcesProvider: React.FC<Props> = ({ children }) => {
  const {module: cv, status: cvStatus} = useWasmModule('../assets/opencv/opencv.js');
  const [resources, setResources] = useState<StaticResources>(initialResources);

  useEffect(() => {
    const resources: StaticResources = {
      allReady: cvStatus===Status.LOADED,
      cv: cv
    }
    setResources(resources);
  }, [cvStatus]);  // TODO: why can't we use resources.allReady as the trigger instead?

  return (
    <context.Provider value={resources}>
      {children}
    </context.Provider>
  );
};

export default StaticResourcesProvider;

export { context as staticResourcesContext }
